import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as rowActions from "controllers/payments/row_actions";
import * as gridUtils from "grid/utils";
import * as datasource from "grid/datasource";
import * as http from "utils/http";
import { EDITABLE_AMOUNT_COL_DEF } from "controllers/allocations/columns";
import { ColumnsState } from "grid/columns_state";
import { ToggleAllRows } from "grid/toggleAllRows";
import { PaymentManager } from "./payment_manager";
import { ACTIONS_COL_DEF, AMOUNT_COL_DEF, DEFAULT_COL_DEF } from "../../grid/columns";

interface DuePayment {
  account_number: string;
  invoice_number: string;
  company_name: string;
  due_date: string;
  due_amount: number;
  payment_amount: number;
}

interface CtrlCallbacks {
  subscribeOnResetColumnWidths(callback: () => void): void;
}

export default class extends Controller {
  static targets = ["grid"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;

  gridApi: AgGrid.GridApi;
  paymentManager: PaymentManager;

  connect() {
    if (this.hasGridTarget) {
      const $resetGridBtn: HTMLElement = document.querySelector(".reset-grid");
      const paySelectedButton = document.querySelector(".pay-selected") as HTMLButtonElement;
      this.paymentManager = new PaymentManager(paySelectedButton);
      this.gridApi = this.buildGrid(
        this.gridTarget,
        {
          onDataReceivedSuccess: (response: any) => {
            this.paymentManager.updatePayButtonState();
          },
        },
        {
          subscribeOnResetColumnWidths: (callback: () => void) => {
            $resetGridBtn?.addEventListener("click", callback);
          },
        },
      );
      this.paymentManager.setGridApi(this.gridApi);
    }
  }

  buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
    const { i18nJson, paginationPageSize } = $grid.dataset;
    const colDefs: AgGrid.ColDef[] = [
      { field: "select_payment", width: 36, checkboxSelection: true, headerComponent: ToggleAllRows },
      { field: "customer_number", ...DEFAULT_COL_DEF, editable: false },
      { field: "account_number", ...DEFAULT_COL_DEF, editable: false },
      { field: "invoice_number", ...DEFAULT_COL_DEF, editable: false },
      { field: "company_name", ...DEFAULT_COL_DEF, editable: false },
      { field: "due_date", ...DEFAULT_COL_DEF, editable: false },
      { field: "due_amount", ...AMOUNT_COL_DEF, editable: false },
      { field: "payment_amount", ...EDITABLE_AMOUNT_COL_DEF, editable: true },
      {
        field: "actions",
        ...ACTIONS_COL_DEF,
        cellClass: "actions-cell",
        headerComponentParams: { displayName: "" },
        onCellClicked: (params) => {
          rowActions.onActionCellClicked(params, (action) => {
            switch (action) {
              case rowActions.Action.EDIT:
                return this.handleEdit(params);
              case rowActions.Action.PROVISION:
                return this.handleProvision(params);
              case rowActions.Action.PAY:
                return this.handlePay(params);
            }
          });
        },
      },
    ];

    const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, limit: paginationPageSize });
    // prevent double-acting with checkbox
    const { maxBlocksInCache, onCellFocused, ...defaultOptions } = gridUtils.DEFAULT_GRID_OPTIONS;

    const options: AgGrid.GridOptions<DuePayment> = {
      ...defaultOptions,
      defaultColDef: {
        ...(gridUtils.DEFAULT_GRID_OPTIONS.defaultColDef as AgGrid.ColDef<DuePayment, any>),
      },
      datasource: dataSource,
      onCellEditingStopped(params) {
        if (params.newValue <= 0 && params.column.colId === "payment_amount") {
          params.node.setDataValue(params.column, params.oldValue);
        }
      },
      editType: "fullRow",
      cacheBlockSize: parseInt(paginationPageSize),
      columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
      onColumnResized: (event: AgGrid.ColumnResizedEvent) => {
        columnsState.onResize(event);
      },
      onRowEditingStarted: rowActions.GRID_CALLBACKS.onRowEditingStarted,
      onRowEditingStopped: rowActions.GRID_CALLBACKS.onRowEditingStopped,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      suppressRowDeselection: true,
      pagination: true,
      paginationPageSize: parseInt(paginationPageSize),
    };
    const gridApi = AgGrid.createGrid($grid, options);
    dataSource.gridApi = gridApi;
    const columnsState = new ColumnsState(gridApi, "payments_due_payments");
    columnsState.initialize();

    ctrlCallbacks.subscribeOnResetColumnWidths(() => {
      columnsState.reset();
    });
    return gridApi;
  }

  async handleProvision(params) {
    if (!this.gridApi) return;
    this.gridApi.stopEditing();
    const cell = gridUtils.actionsCell(params);
    rowActions.showNormalMode(cell);
  }

  handleEdit(params) {
    params.api.startEditingCell({ rowIndex: params.node.rowIndex, colKey: "payment_amount" });
    const cell = gridUtils.actionsCell(params);
    rowActions.showEditMode(cell);
  }

  async handlePay(params) {
    await this.paymentManager.handlePay(params);
  }

  backToPreviousPage(event: Event) {
    window.history.back();
  }

  disconnect() {
    if (this.gridApi) {
      this.paymentManager.cleanup();
      this.gridApi.destroy();
    }
  }
}
