import * as AgGrid from "ag-grid-community";
import * as dom from "../../utils/dom";
import * as gridUtils from "../../grid/utils";

export enum Action {
  EDIT = "edit",
  PROVISION = "provision",
  PAY = "pay",
}

export function showEditMode($agCell: HTMLElement) {
  if ($agCell) {
    dom.hideElement($agCell.querySelector<HTMLButtonElement>(`button[data-action="${Action.EDIT}"]`));
    dom.showElement($agCell.querySelector<HTMLButtonElement>(`button[data-action="${Action.PROVISION}"]`));
  }
}

export function showNormalMode($agCell: HTMLElement) {
  if ($agCell) {
    dom.hideElement($agCell.querySelector<HTMLButtonElement>(`button[data-action="${Action.PROVISION}"]`));
    dom.showElement($agCell.querySelector<HTMLButtonElement>(`button[data-action="${Action.EDIT}"]`));
  }
}

export function onActionCellClicked(params: AgGrid.CellClickedEvent, handler: (action: Action) => void) {
  const $target = params.event.target as HTMLElement;
  if (!$target) return;

  let currentElement: HTMLElement | null = $target;
  while (currentElement && currentElement !== params.event.currentTarget) {
    if (currentElement.getAttribute("data-action") === Action.EDIT) {
      handler(Action.EDIT);
      return;
    } else if (currentElement.getAttribute("data-action") === Action.PROVISION) {
      handler(Action.PROVISION);
      return;
    } else if (currentElement.getAttribute("data-action") === Action.PAY) {
      handler(Action.PAY);
      return;
    }
    currentElement = currentElement.parentElement;
  }
}

export const GRID_CALLBACKS: Partial<AgGrid.GridOptions> = {
  onRowEditingStarted(params) {
    showEditMode(gridUtils.actionsCell(params));
  },
  onRowEditingStopped(params) {
    showNormalMode(gridUtils.actionsCell(params));
  },
};
