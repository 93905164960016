import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { time: Number, userId: Number };
  declare timeValue: number;
  declare userIdValue: number;
  idleTimer: number | null = null;

  connect() {
    if (!Number.isInteger(this.userIdValue)) {
      return;
    }
    this.addEventListeners();
    this.resetIdleTimer();
  }

  disconnect() {
    this.removeEventListeners();
    clearTimeout(this.idleTimer);
  }

  addEventListeners() {
    document.addEventListener("keypress", this.resetIdleTimer.bind(this));
    document.addEventListener("mousemove", this.resetIdleTimer.bind(this));
    document.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
  }

  removeEventListeners() {
    document.removeEventListener("keypress", this.resetIdleTimer.bind(this));
    document.removeEventListener("mousemove", this.resetIdleTimer.bind(this));
    document.removeEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
  }

  handleVisibilityChange() {
    if (!document.hidden) {
      this.resetIdleTimer();
    }
  }

  resetIdleTimer() {
    clearTimeout(this.idleTimer);
    this.idleTimer = setTimeout(() => {
      this.logOut();
    }, this.timeValue);
  }

  logOut() {
    Turbo.visit("/sign_out", { action: "replace", method: "delete" });
  }
}
