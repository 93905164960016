export enum KeyboardEvent {
  ARROW_DOWN = "ArrowDown",
  ARROW_UP = "ArrowUp",
  ENTER = "Enter",
  ESCAPE = "Escape",
}

export function subscribeOnEnter($input: HTMLInputElement, callback: () => void) {
  $input.onkeydown = function (event) {
    if (event.key === KeyboardEvent.ENTER) {
      callback();
    }
  };
}
