import * as AgGrid from "ag-grid-community";

export class ColumnsState {
  gridApi: AgGrid.GridApi;
  storageKey: string;

  constructor(gridApi: AgGrid.GridApi, storageKey: string) {
    this.gridApi = gridApi;
    this.storageKey = storageKey;
  }

  initialize(): void {
    if (localStorage.getItem(this.storageKey) == null) {
      this.gridApi.sizeColumnsToFit();
      this.#save();
    } else {
      this.applyStoredState();
    }
  }

  applyStoredState(): void {
    const storedState = this.#fetch();
    if (storedState) {
      this.gridApi.applyColumnState({ state: storedState });
    }
  }

  onResize(event: AgGrid.ColumnResizedEvent): void {
    if (!event.finished) return;
    if (event.source === "flex" && event.columns && this.gridApi.getColumnState().length != event.columns.length) {
      this.#save();
    }
    if (event.source === "uiColumnResized" && event.column) {
      this.#save();
    }
  }

  reset() {
    this.gridApi.sizeColumnsToFit();
    this.#save();
  }

  #fetch(): AgGrid.ColumnState[] | null {
    const columnDefsJson = localStorage.getItem(this.storageKey);
    if (!columnDefsJson) {
      return null;
    }
    const parsedState = JSON.parse(columnDefsJson);
    return Array.isArray(parsedState) && parsedState.length > 0 ? parsedState : null;
  }

  #save() {
    const state = this.gridApi.getColumnState();
    const stateWithoutFlex = state.map((column) => {
      const { flex, ...rest } = column;
      return { ...rest, flex: null };
    });
    localStorage.setItem(this.storageKey, JSON.stringify(stateWithoutFlex));
  }
}
