import * as AgGrid from "ag-grid-community";
import { DefaultCellRenderer } from "./cells/defaultCellRenderer";
import { FlexCellRenderer } from "./cells/flexCellRenderer";
import { LinkCellRenderer } from "./cells/linkCellRenderer";
import overlayLoadingTemplate from "./overlayLoadingTemplate";
import { hide } from "controllers/allocations/row_actions";

export const DEFAULT_GRID_OPTIONS: AgGrid.GridOptions = {
  defaultColDef: {
    cellRenderer: "defaultCellRenderer",
    flex: 1,
    minWidth: 30,
    resizable: true,
    headerClass: "text-sm uppercase",
  },
  components: {
    defaultCellRenderer: DefaultCellRenderer,
    flexCellRenderer: FlexCellRenderer,
    linkCellRenderer: LinkCellRenderer,
  },
  enableCellTextSelection: true,
  // LOADING
  loading: true,
  rowHeight: 48, // Default 44
  overlayLoadingTemplate,
  // PAGINATION =======>
  rowBuffer: 0,
  // rowSelection: "multiple",
  // tell grid we want virtual row model type
  rowModelType: "infinite",
  // how big each page in our page cache will be, default is 100
  pagination: true,
  paginationAutoPageSize: true,
  // how many extra blank rows to display to the user at the end of the dataset,
  // which sets the vertical scroll and then allows the grid to request viewing more rows of data.
  // default is 1, ie show 1 row.
  cacheOverflowSize: 0,
  // how many server side requests to send at a time. if user is scrolling lots, then the requests
  // are throttled down
  maxConcurrentDatasourceRequests: 1,
  // how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
  // the grid is loading from the users perspective (as we have a spinner in the first col)
  infiniteInitialRowCount: 20,
  // how many pages to store in cache. default is undefined, which allows an infinite sized cache,
  // pages are never purged. this should be set for large data to stop your browser from getting
  // full of data
  maxBlocksInCache: 1,
  blockLoadDebounceMillis: 100,
  onCellFocused(event) {
    event.api.getRowNode(event.rowIndex.toString())?.setSelected(true);
  },
  onSortChanged(params) {
    if (params.api.getGridOption("rowModelType") == "infinite") {
      params.api.paginationGoToPage(0);
      params.api.purgeInfiniteCache();
    }
  },
  // <======= PAGINATION
  // debug: true,
  // onCellClicked(event) {
  //     if (event.data.details_path) { window.location.assign(event.data.details_path) }
  // }
};

export function buildColumnDefs(colDefs: AgGrid.ColDef[], { i18n }): AgGrid.ColDef[] {
  return colDefs.map((colDef) => ({
    ...colDef,
    headerName: i18n[colDef.field] || "",
    headerTooltip: i18n[colDef.field] || "",
  }));
}

// export function getColDef(colDefs: AgGrid.ColDef[], field: string) {
//   return colDefs.find(colDef => colDef.field == field)
// }

// GRID helpers

export function actionsCell(params) {
  return params.api.getCellRendererInstances({ columns: ["actions"], rowNodes: [params.node] })[0]?.eGui;
}

export function isEditing(params: AgGrid.CellEvent<any, any, any> | AgGrid.RowEvent<any, any, any>) {
  return params.api.getEditingCells().length > 0;
}

export function isRowPinned(params: AgGrid.CellEvent<any, any, any> | AgGrid.RowEvent<any, any, any>) {
  return !!params.node.rowPinned;
}

export function nodesData(api: AgGrid.GridApi): any[] {
  const data = [];
  api.forEachNode((node) => data.push(node.data));
  return data;
}

export function restartEditingPinnedRow(
  params: AgGrid.CellEditingStoppedEvent | AgGrid.RowEditingStoppedEvent,
  gridApi: AgGrid.GridApi,
) {
  if (isRowPinned(params)) {
    gridApi.startEditingCell({ rowIndex: 0, rowPinned: "top", colKey: "account_number" });
  }
}
