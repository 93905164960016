import * as AgGrid from "ag-grid-community";

export function FlexCellRenderer(params: AgGrid.ICellRendererParams) {
  const $el = document.createElement("div");
  if (!params.value) {
    return;
  }
  $el.classList.add("flex");
  $el.classList.add("items-center");
  $el.classList.add("max-w-xs");
  $el.innerHTML = params.value;
  return $el;
}
