import * as AgGrid from "ag-grid-community";
import * as columns from "../../grid/columns";

// EDIT -----------------------------------------------------------------------

export const EDITABLE_AMOUNT_COL_DEF: AgGrid.ColDef = {
  ...columns.AMOUNT_COL_DEF,
  cellEditor: "agNumberCellEditor",
  cellEditorParams: {
    precision: 2,
    showStepperButtons: true,
    step: 0.01,
  },
  editable: true,
};

export const EDITABLE_DATE_COL_DEF: AgGrid.ColDef = {
  ...columns.DEFAULT_COL_DEF,
  cellDataType: "text",
  cellEditor: "agTextCellEditor",
  cellEditorParams: {
    maxLength: 10,
  },
  editable: true,
};

export const EDITABLE_TEXT_COL_DEF: AgGrid.ColDef = {
  ...columns.DEFAULT_COL_DEF,
  cellDataType: "text",
  cellEditor: "agTextCellEditor",
  cellEditorParams: { maxLength: 250 },
  editable: true,
};

export const EDITABLE_ACCOUNT_NUMBER_COL_DEF: AgGrid.ColDef = {
  ...columns.DEFAULT_COL_DEF,
  ...EDITABLE_TEXT_COL_DEF,
  editable(params) {
    return !!params.node.rowPinned;
  },
};

// UTILS ----------------------------------------------------------------------
function addDays(date: Date, days: number) {
  date.setDate(date.getDate() + days);
  return date;
}
