import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput"];
  static values = {
    redirectPath: String,
  };

  declare searchInputTarget: HTMLInputElement;
  declare redirectPathValue: string;
  declare hasRedirectPathValue: boolean;

  clearInput(): void {
    this.searchInputTarget.value = "";
  }

  clearInputAndSearchUrl(): void {
    if (this.hasRedirectPathValue && this.searchInputTarget.value !== "") {
      window.location.href = this.redirectPathValue;
    }
  }
}
