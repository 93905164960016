import { Controller } from "@hotwired/stimulus";
import * as AgGrid from "ag-grid-community";
import * as datasource from "../../grid/datasource";
import * as gridUtils from "../../grid/utils";
import { ColumnsState } from "grid/columns_state";

import { ACTIONS_COL_DEF, DATE_FILTER_COL_DEF, DEFAULT_COL_DEF, TEXT_FILTER_COL_DEF } from "../../grid/columns";

interface Report {
  upload_name: string;
  upload_date: string;
}
interface CtrlCallbacks {
  subscribeOnResetColumnWidths(callback: () => void): void;
}

function buildGrid($grid: HTMLElement, dataCallbacks: datasource.DatasourceCallbacks, ctrlCallbacks: CtrlCallbacks) {
  const { i18nJson, paginationPageSize, storageKey } = $grid.dataset;

  const colDefs: AgGrid.ColDef[] = [
    { field: "upload_name", ...DEFAULT_COL_DEF, ...TEXT_FILTER_COL_DEF, sortable: true },
    { field: "upload_date", ...DEFAULT_COL_DEF, ...DATE_FILTER_COL_DEF, maxWidth: 300, sortable: true, sort: "desc" },
    { field: "actions", ...ACTIONS_COL_DEF },
  ];

  const dataSource = new datasource.Datasource({ callbacks: dataCallbacks, limit: paginationPageSize });

  const options: AgGrid.GridOptions<Report> = {
    ...gridUtils.DEFAULT_GRID_OPTIONS,
    cacheBlockSize: parseInt(paginationPageSize),
    columnDefs: gridUtils.buildColumnDefs(colDefs, { i18n: JSON.parse(i18nJson) }),
    datasource: dataSource,
    onColumnResized: (event: AgGrid.ColumnResizedEvent) => {
      columnsState.onResize(event);
    },
  };

  const gridApi = AgGrid.createGrid($grid, options);
  dataSource.gridApi = gridApi;
  const columnsState = new ColumnsState(gridApi, storageKey);
  columnsState.initialize();

  ctrlCallbacks.subscribeOnResetColumnWidths(() => {
    columnsState.reset();
  });

  return gridApi;
}

export default class extends Controller {
  static targets = ["grid"];

  declare readonly gridTarget: HTMLInputElement;
  declare readonly hasGridTarget: boolean;

  gridApi: AgGrid.GridApi;

  connect() {
    if (this.hasGridTarget) {
      const $resetGridBtn: HTMLElement = document.querySelector(".reset-grid");

      this.gridApi = buildGrid(
        this.gridTarget,
        {},
        {
          subscribeOnResetColumnWidths(callback: () => void) {
            $resetGridBtn.addEventListener("click", callback);
          },
        },
      );
    }
  }

  disconnect() {
    this.gridApi?.destroy();
  }
}
