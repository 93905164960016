import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import * as http from "utils/http";

export interface PaymentData {
  account_number: string;
  currency_code: string;
  customer_number: string;
  invoice_number: string;
  due_amount: number;
  payment_amount: number;
}

export class PaymentManager {
  private gridApi: GridApi | null = null;
  private selectedPayments: PaymentData[] = [];
  private paySelectedButton: HTMLButtonElement;
  private paySelectedPath: string;

  constructor(paySelectedButton: HTMLButtonElement) {
    this.paySelectedButton = paySelectedButton;
    this.updatePayButtonState();
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.handlePay = this.handlePay.bind(this);
    this.paySelectedPath = this.paySelectedButton.dataset.paySelectedPath || "";
    this.paySelectedButton.addEventListener("click", this.handlePay);
  }

  setGridApi(gridApi: GridApi) {
    this.gridApi = gridApi;
    this.gridApi.addEventListener("selectionChanged", this.handleSelectionChanged);
  }

  handleSelectionChanged(event: SelectionChangedEvent) {
    if (!this.gridApi) return;
    const selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedPayments = selectedNodes.map((node) => this.extractPaymentData(node.data));
    this.updatePayButtonState();
    this.updateHeaderCheckboxState();
  }

  private extractPaymentData(data: any): PaymentData {
    const { account_number, currency_code, customer_number, invoice_number, due_amount, payment_amount } = data;
    return { account_number, currency_code, customer_number, invoice_number, due_amount, payment_amount };
  }

  updatePayButtonState() {
    const count = this.selectedPayments.length;
    this.paySelectedButton.disabled = count === 0;
    const spanElement = this.paySelectedButton.querySelector("span");
    if (spanElement) {
      spanElement.textContent = `Pay for ${count} Account${count !== 1 ? "s" : ""}`;
    }
  }

  updateHeaderCheckboxState() {
    const columnDef = this.gridApi.getColumnDef("select_payment");
    if (columnDef && columnDef.headerComponent) {
      const headerComponent = columnDef.headerComponent as any;
      if (typeof headerComponent.updateCheckboxState === "function") {
        headerComponent.updateCheckboxState();
      }
    }
  }

  async handlePay(params = null) {
    let paymentInfo;

    if (params instanceof PointerEvent) {
      const selectedNodes = this.gridApi.getSelectedNodes();
      paymentInfo = Array.from(selectedNodes.map((node) => this.extractPaymentData(node.data)));
    } else {
      paymentInfo = [this.extractPaymentData(params.data)];
    }

    try {
      const response = await http.httpPost(this.paySelectedPath, {
        selected_payments: paymentInfo,
        redirect_url: window.location.href,
      });

      if (response.ok) {
        const data = await response.json();
        window.location.replace(data["redirect_url"]);
      } else {
        const errorData = await response.json();
        console.error(`Error processing payments: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Error in handlePay:", error);
    }
  }

  cleanup() {
    this.paySelectedButton.removeEventListener("click", this.handlePay);
    this.gridApi.removeEventListener("selectionChanged", this.handleSelectionChanged);
  }
}
