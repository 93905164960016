import * as AgGrid from "ag-grid-community";

export function LinkCellRenderer(params: AgGrid.ICellRendererParams) {
  const $el = document.createElement("div");
  $el.innerHTML = params.value !== null && params.value !== undefined ? params.value : "";

  const links = $el.querySelectorAll("a");
  links.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.stopPropagation(); // Prevent triggering the grid's onCellClicked
      window.location.href = link.getAttribute("href");
    });
  });

  return $el;
}
