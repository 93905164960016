import * as AgGrid from "ag-grid-community";

export function DefaultCellRenderer(params: AgGrid.ICellRendererParams) {
  const $el = document.createElement("div");
  if (!params.value) {
    return;
  }
  $el.innerHTML = params.value;

  return $el;
}
