const HIDDEN_CLASS = "hidden";

export function hideElement(el: HTMLElement) {
  el.classList.add(HIDDEN_CLASS);
}

export function showElement(el: HTMLElement) {
  el.classList.remove(HIDDEN_CLASS);
  if (el instanceof HTMLButtonElement) {
    el.disabled = false;
  }
}
