import { Application } from "@hotwired/stimulus";

declare const window: any;

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;

window.Stimulus = application;

export { application };
